.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.infected {
  border-left: 5px solid #33a3ff;
  border-radius: 0% !important;
  margin: 2% 2% !important;
}

.recovered {
  border-left: 5px solid #3cb371;
  border-radius: 0% !important;
  margin: 2% 2% !important;
}

.deaths {
  border-left: 5px solid #ff3370;
  border-radius: 0% !important;
  margin: 2% 2% !important;
}
